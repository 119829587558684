body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #594efc; */
  font-size: 14px;
}

html {
  font-size: 14px;
}

/* Theme override */
.bg-primary,
.btn-primary {
  background-color: #0090e3 !important;
}

.btn-primary {
  border: 1px solid #ddd;
}

.btn-primary:hover {
  border: 1px solid #0090e3;
}

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

/* Misc */
.site__pageheader {
  font-size: 1.5rem;
  color: #3498db;
}

.site__subheader {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #3498db;
}

.article__link {
  color: #3498db;
  cursor: pointer;
}

.article__link:hover {
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;
}

.table__number {
  display: block;
  text-align: right;
}

.table__text {
  display: block;
  text-align: center;
}

.logo_space {
  margin-top:150px;
}

.landing_wrap {
 display: flex;
 flex-direction: column;
 align-items: stretch;
}

.landing_page {
  height: 100vh;
  color: #003366;
  background: #0062c3;
  background-image: linear-gradient(#003366, #0062c3,  #0062c3, #0062c3);
}
.landing_page_center {
  height: 380px;
  width: 730px;
  padding: 1em 0 0 0;
  margin: 35vh auto;
  background: white;
  border-radius: 15px;
}
.landing_page_language {
  width: 700px;
  padding: 1.5em 1em;
  border-radius: 15px;
  margin: 0em auto 0 auto;
  background: #dedee6;
}
.landing_page_lang_option {
  position: relative;
  top: 1px;
  padding: 0 0 0 1.5em;
  text-align: right;
}
.landing_page_btn {
  color: #0099cc;
  font-size: 1.3em;
  cursor: pointer;
}
.landing_page_btn:hover {
  text-decoration: underline;
}
.landing_page_assortment {
  margin: 0 0 5px 2em;
}
.landing_page_logo_address {
  text-align: center;
}
.landing_page_wait_text {
  font-weight: bold;
  font-style: italic;
  color: #003647;
  font-size: 1.3em;
  width: 300px;
  border-radius: 15px;
  margin: 0 auto;
}

.hoverItem:hover {
  border-color: grey;
  cursor: pointer;
}

.hoverItemAddBorderAndCursor:hover {
  border: 1px solid grey;
  cursor: pointer;
}

.hoverUnderlinePlusPointer:hover {
  cursor: pointer;
  text-decoration: underline;  
}

.darkblue:hover {
  color: #003366;
}

.smallerFont {
  font-size: 0.75em;
}

